import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { array, bool, func, node, object, string } from 'prop-types';

const UiInput = ({
	hasValue = false,
	errorMsgs = [],
	label = '',
	required = false,
	description = '',
	icon = null,
	children = null,
	className = '',
	focused = false,
	buttonIcon = null,
	buttonClickAction = () => {},
	disabled,
	id = '',
	isFileUpload,
}) => {
	return (
		<div
			className={`form-field${focused ? ' form-field--focused' : ''}${
				hasValue ? ' form-field--has-value' : ''
			} ${errorMsgs.length > 0 ? ' form-field--has-errors' : ''} ${
				className ? className : ''
			} ${disabled ? ' form-field--disabled' : ''}`}>
			{label ? (
				<label htmlFor={id}>
					<span>{label}</span> {required ? null : <code>(opt.)</code>}
				</label>
			) : null}

			<div
				className={`form-field__control${
					isFileUpload ? ' form-field__upload' : ''
				}${
					isFileUpload && disabled
						? ' form-field__upload--disabled'
						: ''
				}`}>
				{icon ? (
					<div className='form-field__icon'>
						<FontAwesomeIcon icon={icon} size='lg' fixedWidth />
					</div>
				) : null}
				<div className='form-field__content'>{children}</div>
				{buttonIcon ? (
					<div
						className='form-field__button'
						onMouseUp={buttonClickAction}>
						<FontAwesomeIcon
							icon={buttonIcon}
							size='lg'
							fixedWidth
						/>
					</div>
				) : null}
			</div>

			{errorMsgs.length > 0 ? (
				<div className='info error'>{errorMsgs[0]}</div> // TODO: Handle multiple errors?
			) : (
				<div
					className='info'
					dangerouslySetInnerHTML={{ __html: description }}
				/>
			)}
		</div>
	);
};

UiInput.propTypes = {
	hasValue: bool,
	errorMsgs: array,
	label: string,
	required: bool,
	description: string,
	icon: object,
	children: node,
	className: string,
	focused: bool,
	buttonIcon: object,
	buttonClickAction: func,
	id: string,
};

export default UiInput;
